<template>
  <div>
    <div class="topbasic">
      <!-- <div class="tbtitle">基本信息</div> -->
      <!-- <div class="tbcont">
        <div class="account">
          <el-form
              ref="form"
              :label-position="labelPosition"
              :model="formLabelAlign"
              label-width="120px"
          >
            <el-form-item label="账户：">
              <span>{{ user.username }}</span>
            </el-form-item>
            <el-form-item label="账户权限：">
              <span v-if="user.roles">{{ user.roles.join(',') }}</span>
            </el-form-item>
          </el-form>
        </div>
        <div class="account">
          <el-form
              ref="form"
              :label-position="labelPosition"
              :model="formLabelAlign"
              label-width="120px"
          >
            <el-form-item label="联系电话：">
              <div v-if="!edit_phone">
                <span>{{ user.telephone }}</span>
                <i class="el-icon-edit" @click="edit_phone=true"></i>
              </div>
              <div v-else>
                <el-input v-model="user.telephone" ></el-input>
                <i class="el-icon-check" @click="editSelf"></i>
              </div>

            </el-form-item>
            <el-form-item label="联系人：">
              <div v-if="!edit_username">
                <span>{{ user.name }}</span>
                <i class="el-icon-edit" @click="edit_username=true"></i>
              </div>
              <div v-else>
                <el-input v-model="user.name" ></el-input>
                <i class="el-icon-check" @click="editSelf"></i>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="account">
          <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              class="upload-demo"
              drag
              multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">上传营业执照</div>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </div>
        <div class="account">
          <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              class="upload-demo"
              drag
              multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">能效测评相关证书</div>
            <div class="el-upload__text">
             请将能效测评相关证书打包上传
            </div>
          </el-upload>
        </div>
      </div> -->
    </div>
    <div class="testtable">
      <!-- <div class="tttotle">
        <div class="ttleft">测评人员名单</div>
        <div class="ttright">
          <el-button type="primary" @click="dialogVisible = true"
          >添加
          </el-button
          >
        </div>
      </div>
      <div class="ttcontent">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column align="center" label="姓名" prop="name">
          </el-table-column>
          <el-table-column
              align="center"
              label="联系方式"
              min-width="180"
              prop="tel"
          ></el-table-column>
          <el-table-column align="center" label="职称" prop="work">
          </el-table-column>
          <el-table-column align="center" label="职务" prop="job">
          </el-table-column>
          <el-table-column align="center" label="证书" prop="file">
            <template slot-scope="{row}">
              <span v-if="row.file">证书已上传</span>
              <span v-else>证书未上传</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" style="color: red">
            <template slot-scope="{row}">
              <i class="el-icon-delete" @click="delStaff(row.id)"></i>删除
            </template>
          </el-table-column>
        </el-table>
      </div> -->
      <!-- 分页 -->
      <div class="pageTotal">
        <!-- <div class="block">
          <el-pagination
              :current-page="page"
              :page-count="totalPage"
              :page-size="limit"
              :total="totalCount"
              background
              layout=" prev, pager, next, total, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
        :visible.sync="dialogVisible"
        title="新增测评人员"
        width="30%"
    >
      <div class="cptest">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="form.tel"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="form.type" placeholder="请选择类型">
              <el-option label="测评人员" value="1"></el-option>
              <el-option label="审核人员" value="2"></el-option>
              <el-option label="批准人员" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职称">
            <el-input v-model="form.work"></el-input>
          </el-form-item>
          <el-form-item label="职务">
            <el-input v-model="form.job"></el-input>
          </el-form-item>
          <el-form-item label="证书">
            <el-upload
                :file-list="fileList"
                :on-success="fileUploadSuccess"
                action="http://greenapi.cdmp.tech/api/public/upload/"
                class="upload-demo"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addStaff"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {editSelfApi, staffDelApi, staffListApi, staffStoreApi, userInfoApi} from "../../../../api/project";

export default {
  data() {
    return {
      fileList: [],
      dialogVisible: false,
      form: {},
      tableData: [],
      labelPosition: "right",
      formLabelAlign: {},

      page: 1,
      totalPage: 0,
      limit: 10,
      totalCount: 0,

      user:{},

      edit_phone: false,
      edit_username: false,

    };
  },
  mounted() {
    console.log('用户信息：', this.user);
    this.getStaffList();
    this.getUserInfo();
  },
  methods: {

    //获得主账号信息
    getUserInfo() {
      let params = {
        token: this.$store.state.token,
      };
      let that = this;
      userInfoApi(params).then((res) => {
        console.log(res);
        that.user = res.data;
      });
    },

    //编辑信息
    editSelf() {

      let params = {
        token: this.$store.state.token,
        id: this.$store.state.user.id,
      };
      if (this.edit_phone){
        this.edit_phone = false;
        params.telephone = this.user.telephone;
      }
      if (this.edit_username){
        this.edit_username = false;
        params.name = this.user.name;
      }
      let that = this;
      editSelfApi(params).then((res) => {
        console.log(res);
        that.$message.success("修改成功");
      });
    },

    //新增员工
    addStaff() {
      let params = Object.assign({}, this.form);
      if (this.fileList && this.fileList.length > 0) {
        params.file = this.fileList[0].url;
      }
      params.token = this.$store.state.token;
      let that = this;
      staffStoreApi(params).then(res => {
        console.log(res);
        that.dialogVisible = false
        that.getStaffList();
      })
    },

    //删除员工
    delStaff(id) {
      let params = {
        token: this.$store.state.token,
        id: id,
      };
      let that = this;
      staffDelApi(params).then(res => {
        console.log(res);
        // that.$message.success(res)
        that.getStaffList();
      })
    },

    //获得员工列表
    getStaffList() {
      let params = {
        page: this.page,
        limit: this.limit,
        token: this.$store.state.token,
      };
      let that = this;
      staffListApi(params).then(res => {
        console.log(res);
        that.tableData = res.data;
        that.page = res.page;
        that.pageCount = res.pageCount;
        that.totalCount = res.totalCount;
      })
    },

    //增加员工文件上传成功回调
    fileUploadSuccess(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map(item => {
        return item.response.data;
      })
      console.log(this.fileList);

    },
    handleSizeChange(limit) {
      this.limit = limit;
      this.getStaffList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getStaffList();
    },
  },
};
</script>
<style scoped>
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}

/deep/ .el-dialog__title {
  color: #ffffff;
}

/deep/ .el-dialog {
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  background-color: #3086fb;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}

/deep/ .el-form-item__content > span {
  font-size: 18px;
  font-weight: normal;
  color: #333333;
}

/deep/ .el-form-item__label {
  font-size: 18px;
  font-weight: normal;
  color: #999999;
}

/deep/ .el-upload-dragger {
  border: 1px dashed #3086fb;
}

/deep/ .el-upload-dragger .el-icon-upload {
  margin: 18px 0 16px;
  color: #3086fb;
}

/deep/ .el-upload-dragger .el-upload__text {
  color: #3086fb;
}

/deep/ .el-upload-dragger {
  width: 261px;
  height: 140px;
}

/deep/ .el-icon-edit {
  color: #3086fb;
  margin-left: 10px;
}
</style>
<style lang="scss" scoped>
.topbasic {
  margin: 30px 0;
  padding: 20px 50px;
  height: 230px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);

  .tbtitle {
    margin: 22px 50px;
    font-size: 18px;
    color: #333333;
  }

  .tbcont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.testtable {
  margin-bottom: 20px;

  .tttotle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .ttleft {
      font-size: 18px;
      font-weight: normal;
      color: #333333;
      padding-left: 40px;
    }
  }

  .ttcontent {
    background-color: #ffffff;
  }

  .pageTotal {
    margin-top: 37px;
    display: flex;
    justify-content: center;
  }
}
</style>
